#ReferralDataGrid .referralTableHeader {
  opacity: 0.5;
  font-size: 10px;
}

#ReferralDataGrid .MuiDataGrid-columnHeaders {
  border-bottom: none;
}

#ReferralDataGrid .MuiDataGrid-columnHeader {
  padding-left: 0;
  padding-right: 0;
}

#ReferralDataGrid .MuiDataGrid-row {
  border-bottom: none;
  cursor: pointer;
}

#ReferralDataGrid .MuiDataGrid-cell--withRenderer {
  padding-left: 0;
}

#ReferralDataGrid .MuiDataGrid-cell--textRight {
  padding-right: 0;
}