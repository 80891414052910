/* Global CSS - Prefer adding CSS at the component level. */

html {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(249, 250, 251);
}

html[data-theme='dark'] {
  background-color: #1A1A33;
  color: #FFFFFF;
}

html[data-theme='dark'] {
  background-color: #1A1A33;
  color: #FFFFFF;
}

a {
  color: #009ecf;
}

body {
  margin: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align:left;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s; */
  transition-delay: background-color 5000s, color 5000s;
}

#OrdersDataGrid .ordersTableHeader,
#ReferralsDataGrid .referralsTableHeader {
  opacity: 0.5;
  font-size: 10px;
}

#OrdersDataGrid .MuiDataGrid-columnHeaders,
#ReferralsDataGrid .MuiDataGrid-columnHeaders {
  border-bottom: none;
}

#OrdersDataGrid .MuiDataGrid-row,
#ReferralsDataGrid .MuiDataGrid-row {
  padding-left: 0;
  padding-right: 0;
}

#OrdersDataGrid .MuiDataGrid-row {
  border-bottom: none;
  cursor: pointer;
}

#OrdersDataGrid .MuiDataGrid-cell--withRenderer {
  padding-left: 0;
}

#OrdersDataGrid .MuiDataGrid-cell--textRight {
  padding-right: 0;
}

/* handle text overflow in the member search field using this pseudo class */
#MemberSearchField:placeholder-shown {
  text-overflow: ellipsis;
}

/* style arrow in custom link section dropdown boxes */
#custom-link-section .MuiSvgIcon-root {
  opacity: 50%;
}

.non-selectable {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.pulse {
  animation: pulse 1s infinite;
}
